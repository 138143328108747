import { NavItem } from './nav-item/nav-item';

export const driversItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'aperture',
    route: '/dashboards/dashboard1',
  },
 
  {
    navCap: 'Quick Actions',
  },

  
  
  
  {
    displayName: 'Trip Operations',
    iconName: 'receipt-2',
    route: 'apps/operations',
    children: [
     
      {
        displayName: 'Tasks',
        iconName: 'point',
        route: 'apps/operations/tasks',
      },
     
      {
        displayName: 'Trips',
        iconName: 'point',
        route: 'apps/operations/trips',
      },
    
      {
        displayName: 'Trip Issues',
        iconName: 'point',
        route: 'apps/operations/issues',
      },
      {
        displayName: 'Routes',
        iconName: 'point',
        route: 'apps/operations/routes',
      }
    ],
  },


  {
    displayName: 'Prime Mover',
    iconName: 'tir',
    route: 'apps/vehicles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/vehicles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/vehicles/list',
      }
    ],
  },
  {
    displayName: 'Trailers',
    iconName: 'caravan',
    route: 'apps/trailers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/trailers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/trailers/list',
      }
    ],
  },
 


  {
    displayName: 'Documents',
    iconName: 'books',
    route: 'apps/documents',
    children: [
    
      {
        displayName: 'Documents List',
        iconName: 'point',
        route: 'apps/documents/list',
      }
    ],
  },
  {
    displayName: 'Drivers',
    iconName: 'user-plus',
    route: 'apps/drivers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/drivers/add',
      },
      {
        displayName: 'Drivers',
        iconName: 'point',
        route: 'apps/drivers/list',
      }
    ],
  },


  {
    navCap: 'Settings',
  },
  {
    displayName: 'Account Setting',
    iconName: 'user-circle',
    route: 'apps/users/account',
  },

];
